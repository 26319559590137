<template>
<div v-if="user">
	<template>
		<div class="d-block d-md-flex align-items-center mb-4">
			<h3 class="mr-3 d-block d-md-inline-block mb-md-0 mb-3">
				<i class="fas fa-user icon-lg"></i>
				{{ user.primary_contact.name }}
			</h3>
		</div>

		<div class="form-row font-thaisans m-0">
			<div class="form-group col-6">
				<label>ประเภท Account</label>
				<ColorTag
					class="form-control font-cu d-block d-md-inline-block mb-3 mb-md-0"
					size="lg"
					:label="LABELS.USER_TYPE[user.account_type.code]"
					:color="COLORS.USER_TYPE[user.account_type.code]" />
			</div>
		</div>

		<AdminContactInfo
			:is_user="true"
			:index="user.primary_contact.index"
			@contact-name-edited="on_contact_edit()" />
	
		<template v-if="user.account_type.code !== UserType.ADMIN">
			<h4 class="mt-4 mb-3">
				<i class="fas fa-address-book mr-3"></i>
				รายชื่อ Contact
			</h4>
			<div class="font-thaisans">
				<table class="small-font">
					<thead>
						<tr>
							<th>ประเภท</th>
							<th>ชื่อ</th>
							<th>ที่อยู่</th>
							<th class="d-none d-md-table-cell">ข้อมูลภาษาอังกฤษ</th>
							<th class="d-none d-md-table-cell">ข้อมูลติดต่อ</th>
							<th></th>
						</tr>
					</thead>
					<tbody class="border-t">
						<tr v-for="contact of user.contacts"
								:key="contact.index"
								class="nowrap">
							<td class="py-1">
								<ColorTag
									:label="contact.contact_type"
									:color="COLORS.CONTACT_TYPE[contact.contact_type]" />
							</td>
							<td class="py-1">
								{{ contact.name }}
							</td>
							<td class="py-1 pre-line squeeze-line small-font">
								{{ contact.address }}
							</td>
							<td class="py-1 d-none d-md-table-cell">
								<i  v-if="contact.name_en && contact.address_en"
										class="fas fa-check icon-md text-center text-primary" />
								<i  v-else
										class="fas fa-times icon-md text-center text-danger" />
							</td>
							<td class="py-1 pre-line squeeze-line small-font d-none d-md-table-cell">
								<div class="d-flex flex-column">
									<div class="mb-1">
										<i class="fas fa-envelope icon-sm mr-2"></i> {{ contact.email || '--' }}
									</div>
									<div>
										<i class="fas fa-phone icon-sm mr-2"></i> {{ contact.phone || '--' }}
									</div>
								</div>
							</td>
							<td class="py-1">
								<router-link  :to="{ 
																name: 'admin-contacts-list',
																params: { id: contact.index }
															}"
															tag="a"
															class="btn btn-secondary">
									<i class="fas fa-chevron-right btn-inner-icon mr-0" />
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="user.contacts.length < 1" class="p-4 border-b text-center">
				<h5 class="text-muted">
					<i class="far fa-frown mr-2"></i>ไม่มี Contact
				</h5>
			</div>
		
			<div	v-if="user.account_type.code !== UserType.ADMIN"
						class="form-row border-b mx-0 py-4 mt-4">
				<template v-if="user.account_active">
					<div class="w-100"></div>
					<div class="form-group col-12 col-md-6 mb-0">
						<button class="btn btn-danger btn-block"
										@click="show_deactivate_account_modal()">
							<i class="fas fa-ban btn-inner-icon"></i>
							ระงับการใช้งาน Account
						</button>
					</div>
				</template>
				<div v-else class="form-group col-12 col-md-6 mb-0">
					<button class="btn btn-success btn-block"
									@click="show_reactivate_account_modal()">
						<i class="fas fa-check btn-inner-icon"></i>
						นำ Account กลับมาใช้งาน
					</button>
				</div>
			</div>
		</template>
	</template>

	<Modal  modal-id="deactivate-account-modal"
					x-close>
		<template #modal-header>
			<h3 class="text-danger">
				<i class="fas fa-exclamation-triangle icon-lg mr-2" />ท่านกำลังจะระงับการใช้งาน Account
			</h3>
		</template>
		<template #modal-body>
			<h4 class="mb-2">
				<span class="text-danger">{{ user.primary_contact.name }}</span>
				จะไม่สามารถ login เข้ามาในระบบ แต่ข้อมูลทั้งหมดจะยังคงอยู่
			</h4>
			<h4>ท่านสามารถนำ Account กลับมาใช้งานในภายหลังได้</h4>
			<div class="form-row mt-4">
				<div class="form-group col-4 mb-0">
					<button type="button"
									class="btn btn-secondary btn-block"
									data-dismiss="modal">
						ยกเลิก
					</button>
				</div>
				<div class="form-group col mb-0">
					<button type="button"
									class="btn btn-danger btn-block"
									@click="submit_deactivate_account()">
						ยืนยันว่าจะระงับการใช้งาน Account
					</button>
				</div>
			</div>
		</template>
	</Modal>

	<Modal  modal-id="reactivate-account-modal"
					x-close>
		<template #modal-header>
			<h3 class="text-success">
				<i class="fas fa-exclamation-triangle icon-lg mr-2" />
				ท่านกำลังจะนำ Account กลับมาใช้งาน
			</h3>
		</template>
		<template #modal-body>
			<h4>
				<span class="text-success">{{ user.primary_contact.name }}</span>
				จะสามารถ login เข้ามาในระบบ และทำการส่งตัวอย่างได้
			</h4>
			<div class="form-row mt-4">
				<div class="form-group col-4 mb-0">
					<button type="button"
									class="btn btn-secondary btn-block"
									data-dismiss="modal">
						ยกเลิก
					</button>
				</div>
				<div class="form-group col mb-0">
					<button type="button"
									class="btn btn-success btn-block"
									@click="submit_reactivate_account()">
						ยืนยันว่าจะนำ Account กลับมาใช้งาน
					</button>
				</div>
			</div>
		</template>
	</Modal>

</div>
</template>

<script>
import $ from 'jquery'
import {
	USER_DETAIL,
	USER_DEACTIVATE_ACCOUNT
} from '@/graphql/user'

export default {
	name: 'admin-user-info',
	components: {
		AdminContactInfo: () => import(/* webpackChunkName: "group-admin" */
			'./AdminContactInfo'
		),
	},
	methods: {
		show_deactivate_account_modal () {
			$('#deactivate-account-modal').modal('show')
		},
		async submit_deactivate_account () {
			try {
				let res = await this.$apollo.mutate({
					mutation: USER_DEACTIVATE_ACCOUNT,
					variables: {
						user_index: this.user.index,
						account_active: false
					}
				})
				$('#deactivate-account-modal').modal('hide')
				this.$apollo.queries.user.refetch()
				this.$emit('deactivate-account')
			} catch (err) {
				console.log(err)
			}
		},
		show_reactivate_account_modal () {
			$('#reactivate-account-modal').modal('show')
		},
		async submit_reactivate_account () {
			try {
				let res = await this.$apollo.mutate({
					mutation: USER_DEACTIVATE_ACCOUNT,
					variables: {
						user_index: this.user.index,
						account_active: true
					}
				})
				$('#reactivate-account-modal').modal('hide')
				this.$apollo.queries.user.refetch()
				this.$emit('reactivate-account')
			} catch (err) {
				console.log(err)
			}
		},
		on_contact_edit () {
			this.$apollo.queries.user.refetch()
			this.$emit('refetch-users')
		}
	},
	apollo: {
		user: {
			query: USER_DETAIL,
			variables () {
				return {
					index: parseInt(this.$route.params.id)
				}
			},
			update: data => data.get_backuser
		}
	}
}
</script>