<template>
<div class="page page-lg d-flex py-0 position-relative"> 
	<div class="list-view list-sticky border-r pt-4 pr-4 content-height-with-subnav">
		<SearchInput
			class="mb-2"
			placeholder="ค้นหา User Account..."
			:initial-query="$route.query.query"
			@search="loading = true"
			@debounced-search="apply_search_query($event)" />
		<div class="d-flex mb-2">
			<button v-for="filter of LISTS.USER_TYPES"
							:key="filter"
							class="filter-btn filter-btn-sm btn w-100 mr-2 px-2"
							:class="[
								COLORS.USER_TYPE[filter],
								{'active': user_type_filter == filter}
							]"
							:disabled="loading || user_type_filter == filter"
							@click="apply_user_type_filter(filter)">
				<i  v-if="user_type_filter == filter"
						class="fas fa-check btn-inner-icon" />
				<div  v-else
							class="small-square mr-1"
							:class="COLORS.USER_TYPE[filter]" />
				{{ LABELS.USER_TYPE[filter] }}
			</button>
			<button class="filter-btn filter-btn-sm btn w-100 grey"
							:class="{'active': !account_active_filter}"
							:disabled="loading || !account_active_filter"
							@click="apply_deactivated_user_filter()">
				<i class="fas fa-ban btn-inner-icon" />
				ถูกระงับ
			</button>
		</div>

		<transition name="fade">
			<div  v-if="!loading && users"
						key="list" 
						class="scroll-container">
				<div class="fade-gradient-top"></div>
				<ul v-if="users.length > 0" class="item-list font-thaisans">
					<router-link  :to="{
													name: 'admin-users-list',
													params: { id: user.index },
													query: {
														type: $route.query.type || undefined,
														query: $route.query.query || undefined,
														active: account_active_filter === false? account_active_filter : undefined,
													}
												}"
												tag="li"
												v-for="(user, idx) of users"
												:key="idx"
												class="clickable"
												:class="{
													'active': $route.params.id == user.index,
													'faded': !account_active_filter
												}">
						<div class="row no-gutters">
							<div class="col-9 d-flex align-items-center pr-2">
								<i  v-if="!account_active_filter"
										class="fas fa-ban btn-inner-icon mr-2" />
								<h5 class="ellipsis">{{ user.primary_contact.name }}</h5>
							</div>
							<div class="col-3">
								<ColorTag v-if="$route.query.type == UserType.ADMIN"
									:label="LABELS.USER_TYPE[user.account_type.code]"
									:color="COLORS.USER_TYPE[user.account_type.code]" />
								<ColorTag v-else
									:label="user.primary_contact.contact_type"
									:color="COLORS.CONTACT_TYPE[user.primary_contact.contact_type]" />
							</div>
						</div>
					</router-link>
				</ul>
				<div  v-else class="w-100 py-3 text-center border-b border-t">
					<h5 class="text-muted">
						<i class="far fa-frown mr-2"></i>ไม่มี User Account ที่จะแสดง
					</h5>
				</div>
				<div class="fade-gradient-bottom" />
			</div>
			<div v-else key="loading" class="w-100 pt-5 text-center">
				<LoadingAnimation color="primary" size="lg" />
			</div>
		</transition>
	</div>
	
	<div v-if="$route.params.id" class="pt-4 pl-4 w-100 scroll-container">
		<transition name="fade">
			<AdminUserInfo
				:key="$route.params.id"
				@refetch-users="$apollo.queries.users.refetch()"
				@deactivate-account="on_deactivate_account()"
				@reactivate-account="on_reactivate_account()" />
		</transition>
	</div>
</div>
</template>

<script>
import AdminUserInfo from './AdminUserInfo'
import { USERS_LIST } from '@/graphql/user'
import { UserType } from '@/enum'
import { DeleteCache } from '@/vue-apollo'

export default {
	name: 'admin-users-list',
	components: {
		AdminUserInfo
	},
	data () {
		return {
			loading: true,
		}
	},
	computed: {
		account_active_filter () {
			// excludes undefined and null
			if (this.$route.query.active === false) {
				return false
			}
			return true
		},
		user_type_filter () {
			if (!this.$route.query.type) {
				if (this.account_active_filter === false) {
					return null
				}
				return UserType.SUBMITTER
			}
			return parseInt(this.$route.query.type)
		}
	},
	methods: {
		apply_user_type_filter (type) {
			this.loading = true
			this.$router.push({
				name: 'admin-users-list',
				query: {
					query: this.$route.query.query || undefined,
					type: type || undefined,
				}
			})
		},
		apply_deactivated_user_filter () {
			this.loading = true
			this.$router.push({
				name: 'admin-users-list',
				query: {
					query: this.$route.query.query || undefined,
					active: false,
				}
			})
		},
		apply_search_query (query) {
			if (query == this.$route.query.query) {
				this.loading = false
			} else {
				this.$router.push({
					name: 'admin-users-list',
					params: { id: this.$route.params.id },
					query: {
						type: this.$route.query.type || undefined,
						active: this.$route.query.active === false? false : undefined,
						query: query || undefined,
					}
				})
			}
		},
		on_deactivate_account () {
			this.loading = true
			DeleteCache('search_backuser')
			this.$router.push({
				name: 'admin-users-list',
				params: { id: this.$route.params.id },
				query: {
					query: this.$route.query.query || undefined,
					active: false
				}
			})
		},
		on_reactivate_account () {
			this.loading = true
			DeleteCache('search_backuser')
			this.$router.push({
				name: 'admin-users-list',
				params: { id: this.$route.params.id },
				query: {
					query: this.$route.query.query || undefined,
				}
			})
		}
	},
	apollo: {
		users: {
			query: USERS_LIST,
			variables () {
				return {
					search_query: this.$route.query.query,
					account_type: this.user_type_filter,
					account_active: this.account_active_filter
				}
			},
			update: data => data.search_backuser,
			result () {
				this.$nextTick( () => this.loading = false )
			}
		}
	}
}
</script>