var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-lg d-flex py-0 position-relative"},[_c('div',{staticClass:"list-view list-sticky border-r pt-4 pr-4 content-height-with-subnav"},[_c('SearchInput',{staticClass:"mb-2",attrs:{"placeholder":"ค้นหา User Account...","initial-query":_vm.$route.query.query},on:{"search":function($event){_vm.loading = true},"debounced-search":function($event){return _vm.apply_search_query($event)}}}),_c('div',{staticClass:"d-flex mb-2"},[_vm._l((_vm.LISTS.USER_TYPES),function(filter){return _c('button',{key:filter,staticClass:"filter-btn filter-btn-sm btn w-100 mr-2 px-2",class:[
								_vm.COLORS.USER_TYPE[filter],
								{'active': _vm.user_type_filter == filter}
							],attrs:{"disabled":_vm.loading || _vm.user_type_filter == filter},on:{"click":function($event){return _vm.apply_user_type_filter(filter)}}},[(_vm.user_type_filter == filter)?_c('i',{staticClass:"fas fa-check btn-inner-icon"}):_c('div',{staticClass:"small-square mr-1",class:_vm.COLORS.USER_TYPE[filter]}),_vm._v(" "+_vm._s(_vm.LABELS.USER_TYPE[filter])+" ")])}),_c('button',{staticClass:"filter-btn filter-btn-sm btn w-100 grey",class:{'active': !_vm.account_active_filter},attrs:{"disabled":_vm.loading || !_vm.account_active_filter},on:{"click":function($event){return _vm.apply_deactivated_user_filter()}}},[_c('i',{staticClass:"fas fa-ban btn-inner-icon"}),_vm._v(" ถูกระงับ ")])],2),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.users)?_c('div',{key:"list",staticClass:"scroll-container"},[_c('div',{staticClass:"fade-gradient-top"}),(_vm.users.length > 0)?_c('ul',{staticClass:"item-list font-thaisans"},_vm._l((_vm.users),function(user,idx){return _c('router-link',{key:idx,staticClass:"clickable",class:{
													'active': _vm.$route.params.id == user.index,
													'faded': !_vm.account_active_filter
												},attrs:{"to":{
													name: 'admin-users-list',
													params: { id: user.index },
													query: {
														type: _vm.$route.query.type || undefined,
														query: _vm.$route.query.query || undefined,
														active: _vm.account_active_filter === false? _vm.account_active_filter : undefined,
													}
												},"tag":"li"}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-9 d-flex align-items-center pr-2"},[(!_vm.account_active_filter)?_c('i',{staticClass:"fas fa-ban btn-inner-icon mr-2"}):_vm._e(),_c('h5',{staticClass:"ellipsis"},[_vm._v(_vm._s(user.primary_contact.name))])]),_c('div',{staticClass:"col-3"},[(_vm.$route.query.type == _vm.UserType.ADMIN)?_c('ColorTag',{attrs:{"label":_vm.LABELS.USER_TYPE[user.account_type.code],"color":_vm.COLORS.USER_TYPE[user.account_type.code]}}):_c('ColorTag',{attrs:{"label":user.primary_contact.contact_type,"color":_vm.COLORS.CONTACT_TYPE[user.primary_contact.contact_type]}})],1)])])}),1):_c('div',{staticClass:"w-100 py-3 text-center border-b border-t"},[_c('h5',{staticClass:"text-muted"},[_c('i',{staticClass:"far fa-frown mr-2"}),_vm._v("ไม่มี User Account ที่จะแสดง ")])]),_c('div',{staticClass:"fade-gradient-bottom"})]):_c('div',{key:"loading",staticClass:"w-100 pt-5 text-center"},[_c('LoadingAnimation',{attrs:{"color":"primary","size":"lg"}})],1)])],1),(_vm.$route.params.id)?_c('div',{staticClass:"pt-4 pl-4 w-100 scroll-container"},[_c('transition',{attrs:{"name":"fade"}},[_c('AdminUserInfo',{key:_vm.$route.params.id,on:{"refetch-users":function($event){return _vm.$apollo.queries.users.refetch()},"deactivate-account":function($event){return _vm.on_deactivate_account()},"reactivate-account":function($event){return _vm.on_reactivate_account()}}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }