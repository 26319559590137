<template>
<div v-if="!loading">
	<div v-if="can_go_back()" class="mb-2">
		<button	class="btn btn-transparent back-btn mb-3"
						@click="on_back()">
			<i class="fas fa-chevron-left mr-2" />กลับไปที่หน้า Account
		</button>
	</div>
	<div v-if="!is_user" class="d-block d-md-flex align-items-center mb-4">
		<h3 class="mr-3 d-block d-lg-inline-block mb-md-0 mb-3">
			<i class="fas fa-id-card icon-lg mr-2"></i> {{ contact.name }}
		</h3>
	</div>

	<div class="form-row border-b font-thaisans m-0">
		<div v-if="contact.contact_type !== 'แอดมิน'" class="form-group col-6">
			<label>ประเภท Contact</label>
			<ColorTag
				class="form-control font-cu d-block d-md-inline-block mb-3 mb-md-0"
				size="lg"
				:label="contact.contact_type"
				:color="COLORS.CONTACT_TYPE[contact.contact_type]" />
		</div>
		<div class="w-100"></div>
		<div class="form-group col-12 col-md-6 mb-4">
			<div  v-if="!editing_info"
						class="form-row">
				<FormInput
					class="col-12"
					label="ชื่อ"
					disabled
					:value="contact.name" />
				<FormTextarea
					class="col-12"
					label="ที่อยู่"
					rows="3"
					disabled
					:value="contact.address" />
				<div class="form-group col-12">
					<button class="btn btn-secondary btn-block font-cu"
									@click="edit_info()">
						<i class="fas fa-edit btn-inner-icon"></i>แก้ไขข้อมูล
					</button>
				</div>
			</div>
			<div  v-else
						class="form-row">
				<FormInput
					class="col-12"
					label="ชื่อ"
					v-model="form_info.name" />
				<FormTextarea
					class="col-12"
					label="ที่อยู่"
					rows="3"
					v-model="form_info.address" />
				<div class="form-group col font-cu">
					<button class="btn btn-primary btn-block"
									@click="submit_edit_info()">
						<i class="fas fa-check btn-inner-icon"></i>บันทึกข้อมูล
					</button>
					<ErrorBox v-if="info_error" :msg="info_error" />
				</div>
				<div class="form-group col-auto pl-0">
					<button class="btn btn-secondary btn-block font-cu"
									@click="cancel_edit_info()">
						<i class="fas fa-times btn-inner-icon mr-0" />
					</button>
				</div>
			</div>
		</div>

		<div class="form-group col-12 col-md-6">
			<div  v-if="!editing_english_info"
						class="form-row">
				<FormInput
					class="col-12"
					label="ชื่อ (ภาษาอังกฤษ)"
					disabled
					:value="contact.name_en" />
				<FormTextarea
					class="col-12"
					label="ที่อยู่ (ภาษาอังกฤษ)"
					rows="3"
					disabled
					:value="contact.address_en" />
				<div class="form-group col-12">
					<button class="btn btn-secondary btn-block font-cu"
									@click="edit_english_info()">
						<i class="fas fa-edit btn-inner-icon"></i>แก้ไขข้อมูลภาษาอังกฤษ
					</button>
				</div>
			</div>
			<div  v-else
						class="form-row">
				<FormInput
					class="col-12"
					label="ชื่อ (ภาษาอังกฤษ)"
					v-model="form_english_info.name_en" />
				<FormTextarea
					class="col-12"
					label="ที่อยู่ (ภาษาอังกฤษ)"
					rows="3"
					v-model="form_english_info.address_en" />
				<div class="form-group col font-cu">
					<button class="btn btn-primary btn-block"
									@click="submit_edit_english_info()">
						<i class="fas fa-check btn-inner-icon"></i>บันทึกข้อมูล
					</button>
					<ErrorBox v-if="english_info_error" :msg="english_info_error" />
				</div>
				<div class="form-group col-auto pl-0">
					<button class="btn btn-secondary btn-block font-cu"
									@click="cancel_edit_english_info()">
						<i class="fas fa-times btn-inner-icon mr-0" />
					</button>
				</div>
			</div>
		</div>

	</div>

	<div class="form-row border-b py-4 font-thaisans m-0">
		<div class="form-group col-12 col-md-6 mb-0">
			<div  v-if="!editing_email"
						class="form-row">
				<FormInput
					class="col"
					label="อีเมล"
					disabled
					:value="contact.email" />
				<div class="form-group col-auto">
					<label></label>
					<button class="btn btn-secondary btn-block font-cu"
									:disabled="is_user"
									@click="edit_email()">
						<i class="fas fa-edit btn-inner-icon mr-0"></i>
					</button>
				</div>
			</div>
			<div  v-else
						class="form-row">
				<FormInput
					class="col"
					label="อีเมล"
					v-model="form_email" />
				<div class="form-group col-auto pr-0">
					<label></label>
					<button class="btn btn-primary btn-block font-cu"
									@click="submit_edit_email()">
						<i class="fas fa-check btn-inner-icon mr-0"></i>
					</button>
				</div>
				<div class="form-group col-auto">
					<label></label>
					<button class="btn btn-secondary btn-block font-cu"
									:disabled="!!contact.primary_contact_of"
									@click="cancel_edit_email()">
						<i class="fas fa-times btn-inner-icon mr-0"></i>
					</button>
				</div>
				<div v-if="email_error" class="form-group col-12 font-cu">
					<ErrorBox :msg="email_error" />
				</div>
			</div>
		</div>

		<div class="form-group col-12 col-md-6 mb-0">
			<div  v-if="!editing_phone"
						class="form-row">
				<FormInput
					class="col"
					label="หมายเลขโทรศัพท์"
					disabled
					:value="contact.phone" />
				<div class="form-group col-auto">
					<label></label>
					<button class="btn btn-secondary btn-block font-cu"
									@click="edit_phone()">
						<i class="fas fa-edit btn-inner-icon mr-0"></i>
					</button>
				</div>
			</div>
			<div  v-else
						class="form-row">
				<FormPhoneInput
					ref="Phone"
					class="col"
					v-model="form_phone" />
				<div class="form-group col-auto pr-0">
					<label></label>
					<button class="btn btn-primary btn-block font-cu"
									@click="submit_edit_phone()">
						<i class="fas fa-check btn-inner-icon mr-0"></i>
					</button>
				</div>
				<div class="form-group col-auto">
					<label></label>
					<button class="btn btn-secondary btn-block font-cu"
									@click="cancel_edit_phone()">
						<i class="fas fa-times btn-inner-icon mr-0"></i>
					</button>
				</div>
				<div v-if="phone_error" class="form-group col-12 font-cu">
					<ErrorBox :msg="phone_error" />
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { get_from_route } from '@/router'
import { CONTACT_DETAIL, UPDATE_CONTACT } from '@/graphql/contact'

export default {
	name: 'admin-contact-info',
	props: {
		index: {
			type: Number,
			required: true,
		},
		show_title: {
			type: Boolean,
			default: true
		},
		is_user: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			loading: true,
			editing_info: false,
			editing_english_info: false,
			editing_email: false,
			editing_phone: false,
			info_error: null,
			english_info_error: null,
			email_error: null,
			phone_error: null,
			form_info: {
				name: null,
				address: null
			},
			form_english_info: {
				name_en: null,
				address_en: null
			},
			form_email: null,
			form_phone: null,
		}
	},
	methods: {
		edit_info () {
			this.form_info.name = this.contact.name
			this.form_info.address = this.contact.address
			this.editing_info = true
		},
		cancel_edit_info () {
			this.info_error = null
			this.form_info.name = null
			this.form_info.address = null
			this.editing_info = false
		},
		edit_english_info () {
			this.form_english_info.name_en = this.contact.name_en
			this.form_english_info.address_en = this.contact.address_en
			this.editing_english_info = true
		},
		cancel_edit_english_info () {
			this.english_info_error = null
			this.form_english_info.name = null
			this.form_english_info.address = null
			this.editing_english_info = false
		},
		edit_email () {
			this.form_email = this.contact.email
			this.editing_email = true
		},
		edit_phone () {
			this.form_phone = this.contact.phone
			this.editing_phone = true
		},
		cancel_edit_email () {
			this.email_error = null
			this.form_email = null
			this.editing_email = false
		},
		cancel_edit_phone () {
			this.phone_error = null
			this.form_phone = null
			this.editing_phone = false
		},
		async submit_edit_info () {
			if (!this.form_info.name || !this.form_info.address) {
				this.info_error = 'กรุณากรอกข้อมูลให้ครบ'
				return
			}
			try {
				let res = await this.$apollo.mutate({
					mutation: UPDATE_CONTACT,
					variables: {
						contact_index: this.contact.index,
						...this.form_info
					}
				})
				await this.$apollo.queries.contact.refetch()
				this.$emit('contact-name-edited')
			} catch (err) {
				this.info_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		},
		async submit_edit_english_info () {
			if (!this.form_english_info.name_en || !this.form_english_info.address_en) {
				this.english_info_error = 'กรุณากรอกข้อมูลให้ครบ'
				return
			}
			try {
				let res = await this.$apollo.mutate({
					mutation: UPDATE_CONTACT,
					variables: {
						contact_index: this.contact.index,
						...this.form_english_info
					}
				})
				await this.$apollo.queries.contact.refetch()
			} catch (err) {
				this.english_info_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		},
		async submit_edit_email () {
			if (!this.form_email) {
				this.email_error = 'กรุณากรอกอีเมล'
				return
			}
			try {
				let res = await this.$apollo.mutate({
					mutation: UPDATE_CONTACT,
					variables: {
						contact_index: this.contact.index,
						email: this.form_email
					}
				})
				await this.$apollo.queries.contact.refetch()
			} catch (err) {
				this.email_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		},
		async submit_edit_phone () {
			if (!this.form_phone) {
				this.phone_error = 'กรุณากรอกหมายเลขโทรศัพท์'
				return
			}
			try {
				let res = await this.$apollo.mutate({
					mutation: UPDATE_CONTACT,
					variables: {
						contact_index: this.contact.index,
						phone: this.form_phone
					}
				})
				await this.$apollo.queries.contact.refetch()
			} catch (err) {
				this.english_info_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		},
		can_go_back () {
			return get_from_route().name === 'admin-users-list'
		},
		on_back () {
			this.$router.go(-1)
		}
	},
	apollo: {
		contact: {
			query: CONTACT_DETAIL,
			variables () {
				return {
					index: this.index,
				}
			},
			update: data => data.get_contact,
			skip () {
				return !this.$route.params.id
			},
			result () {
				this.cancel_edit_info()
				this.cancel_edit_english_info()
				this.cancel_edit_email()
				this.cancel_edit_phone()
				this.$nextTick( () => this.loading = false )
			}
		}
	}
}
</script>