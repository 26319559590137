<template>
<div class="page page-lg d-flex py-0 position-relative">
	<div class="list-view list-sticky border-r pt-4 pr-4 content-height-with-subnav">
		<SearchInput
			class="mb-2"
			placeholder="ค้นหา Contact..."
			:initial-query="$route.query.query"
			@search="loading = true"
			@debounced-search="apply_search_query($event)" />
		<div class="d-flex mb-2">
			<button class="filter-btn filter-btn-sm btn btn-sm primary w-100"
							:class="{'active': !$route.query.type}"
							:disabled="loading || !$route.query.type"
							@click="apply_contact_type_filter(null)">
				<i  v-if="!$route.query.type"
						class="fas fa-check btn-inner-icon" />
				ทั้งหมด
			</button>
			<button v-for="filter of LISTS.CONTACT_TYPES"
							:key="filter"
							class="filter-btn filter-btn-sm btn btn-sm w-100 ml-2 px-2"
							:class="[
								COLORS.CONTACT_TYPE[filter],
								{'active': $route.query.type == filter}
							]"
							:disabled="loading || !$route.query.type == filter"
							@click="apply_contact_type_filter(filter)">
				<i  v-if="$route.query.type == filter"
						class="fas fa-check btn-inner-icon" />
				<div  v-else
							class="small-square mr-1"
							:class="COLORS.CONTACT_TYPE[filter]" />
				{{ filter }}
			</button>
		</div>

		<transition name="fade">
			<div  v-if="!loading && contacts"
						key="list"
						class="scroll-container">
				<div class="fade-gradient-top"></div>
				<ul v-if="contacts.length > 0"
						class="item-list font-thaisans">
					<router-link  :to="{
													name: 'admin-contacts-list',
													params: { id: contact.index },
													query: {
														type: $route.query.type || undefined,
														query: $route.query.query || undefined,
													}
												}"
												tag="li"
												v-for="contact of contacts"
												:key="contact.index"
												class="clickable"
												:class="{'active': $route.params.id == contact.index}"
												@click="selected_contact = null">
						<div class="row no-gutters">
							<div class="col-9 pr-2 d-flex align-items-center">
								<h5 class="ellipsis">{{ contact.name }}</h5>
							</div>
							<div class="col d-flex justify-content-between align-items-center">
								<ColorTag
									:label="contact.contact_type"
									:color="COLORS.CONTACT_TYPE[contact.contact_type]" />
							</div>
						</div>
					</router-link>
				</ul>
				<div  v-else class="w-100 py-3 text-center border-b border-t">
					<h5 class="text-muted">
						<i class="far fa-frown mr-2"></i>ไม่มี Contact ที่จะแสดง
					</h5>
				</div>
				<div class="fade-gradient-bottom"></div>
			</div>
			<div  v-else
						key="loading"
						class="w-100 pt-5 text-center">
				<LoadingAnimation color="primary" size="lg" />
			</div>
		</transition>
	</div>

	<div class="pt-4 pl-4 w-100 scroll-container">
		<AdminContactInfo
			:index="parseInt($route.params.id)"
			@contact-edited="$apollo.queries.contacts.refetch()" />
	</div>
</div>
</template>

<script>
import { CONTACTS_LIST } from '@/graphql/contact'

export default {
	name: 'admin-contacts-list',
	components: {
		AdminContactInfo: () => import(/* webpackChunkName: "group-admin" */
			'./AdminContactInfo'
		),
	},
	data () {
		return {
			loading: true,
		}
	},
	methods: {
		apply_contact_type_filter (type) {
			this.loading = true
			this.$router.push({
				name: 'admin-contacts-list',
				query: {
					query: this.$route.query.query || undefined,
					type: type || undefined,
				}
			})
		},
		apply_search_query (query) {
			if (query == this.$route.query.query) {
				this.loading = false
			} else {
				this.$router.push({
					name: 'admin-contacts-list',
					params: { id: this.$route.params.id },
					query: {
						type: this.$route.query.type || undefined,
						query: query || undefined,
					}
				})
			}
		},
	},
	apollo: {
		contacts: {
			query: CONTACTS_LIST,
			variables () {
				return {
					search_query: this.$route.query.query,
					contact_type: this.$route.query.type,
					no_primary_contacts: true,
				}
			},
			update: data => data.search_contact,
			result () {
				this.$nextTick( () => this.loading = false )
			}
		},
	}
}
</script>