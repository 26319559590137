var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-lg d-flex py-0 position-relative"},[_c('div',{staticClass:"list-view list-sticky border-r pt-4 pr-4 content-height-with-subnav"},[_c('SearchInput',{staticClass:"mb-2",attrs:{"placeholder":"ค้นหา Contact...","initial-query":_vm.$route.query.query},on:{"search":function($event){_vm.loading = true},"debounced-search":function($event){return _vm.apply_search_query($event)}}}),_c('div',{staticClass:"d-flex mb-2"},[_c('button',{staticClass:"filter-btn filter-btn-sm btn btn-sm primary w-100",class:{'active': !_vm.$route.query.type},attrs:{"disabled":_vm.loading || !_vm.$route.query.type},on:{"click":function($event){return _vm.apply_contact_type_filter(null)}}},[(!_vm.$route.query.type)?_c('i',{staticClass:"fas fa-check btn-inner-icon"}):_vm._e(),_vm._v(" ทั้งหมด ")]),_vm._l((_vm.LISTS.CONTACT_TYPES),function(filter){return _c('button',{key:filter,staticClass:"filter-btn filter-btn-sm btn btn-sm w-100 ml-2 px-2",class:[
								_vm.COLORS.CONTACT_TYPE[filter],
								{'active': _vm.$route.query.type == filter}
							],attrs:{"disabled":_vm.loading || !_vm.$route.query.type == filter},on:{"click":function($event){return _vm.apply_contact_type_filter(filter)}}},[(_vm.$route.query.type == filter)?_c('i',{staticClass:"fas fa-check btn-inner-icon"}):_c('div',{staticClass:"small-square mr-1",class:_vm.COLORS.CONTACT_TYPE[filter]}),_vm._v(" "+_vm._s(filter)+" ")])})],2),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.contacts)?_c('div',{key:"list",staticClass:"scroll-container"},[_c('div',{staticClass:"fade-gradient-top"}),(_vm.contacts.length > 0)?_c('ul',{staticClass:"item-list font-thaisans"},_vm._l((_vm.contacts),function(contact){return _c('router-link',{key:contact.index,staticClass:"clickable",class:{'active': _vm.$route.params.id == contact.index},attrs:{"to":{
													name: 'admin-contacts-list',
													params: { id: contact.index },
													query: {
														type: _vm.$route.query.type || undefined,
														query: _vm.$route.query.query || undefined,
													}
												},"tag":"li"},on:{"click":function($event){_vm.selected_contact = null}}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-9 pr-2 d-flex align-items-center"},[_c('h5',{staticClass:"ellipsis"},[_vm._v(_vm._s(contact.name))])]),_c('div',{staticClass:"col d-flex justify-content-between align-items-center"},[_c('ColorTag',{attrs:{"label":contact.contact_type,"color":_vm.COLORS.CONTACT_TYPE[contact.contact_type]}})],1)])])}),1):_c('div',{staticClass:"w-100 py-3 text-center border-b border-t"},[_c('h5',{staticClass:"text-muted"},[_c('i',{staticClass:"far fa-frown mr-2"}),_vm._v("ไม่มี Contact ที่จะแสดง ")])]),_c('div',{staticClass:"fade-gradient-bottom"})]):_c('div',{key:"loading",staticClass:"w-100 pt-5 text-center"},[_c('LoadingAnimation',{attrs:{"color":"primary","size":"lg"}})],1)])],1),_c('div',{staticClass:"pt-4 pl-4 w-100 scroll-container"},[_c('AdminContactInfo',{attrs:{"index":parseInt(_vm.$route.params.id)},on:{"contact-edited":function($event){return _vm.$apollo.queries.contacts.refetch()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }